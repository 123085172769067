// Util
import { DateUtil } from '@/utils/dateutil';
import { ImportUtil } from '@/utils/importUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// Others
import config from '@/config/env-constants';
import _ from 'lodash';


function exceedMaximumAssetTypes(assetsObj) {
	let assetTypes = _.uniq(_.map(assetsObj, 'assetType'));
	return _.size(assetTypes) > 30;
}

function getDefaultDispatchObj() {
	return {
		dispatchId: '',
		source: {},
		destination: {},
		sourceScanners: [],
		destinationScanners: [],
		transportation: { ...config.dispatchTransportDefaultValue },
		driver: { ...config.dispatchDriverDefaultValue },
		newTransportationToAdd: {},
		assets: [],
		inputAssetLog: { ...config.dispatchInputAssetLogDefaultValue },
		damagedAssets: [],
		status: 'Draft',
		notes: '',
		fromInactiveNode: 'false',
		toInactiveNode: 'false',
		creationSource: config.dispatchCreationSource.DISPATCH
	};
}

function cleanupFields(dispatch) {
	let cleanedDispatch = { ...dispatch };

	delete cleanedDispatch['Source'];
	delete cleanedDispatch['Destination'];
	delete cleanedDispatch['Trucker'];
	delete cleanedDispatch['Truck Assistant'];

	delete cleanedDispatch['Dispatched Assets'];
	delete cleanedDispatch['Dispatched'];
	delete cleanedDispatch['Received Assets'];
	delete cleanedDispatch['Received'];

	delete cleanedDispatch['Date Created'];
	delete cleanedDispatch['Date Deployed'];
	delete cleanedDispatch['Date Cancelled'];
	delete cleanedDispatch['Date Updated'];
	delete cleanedDispatch['newCompanyToAdd'];
	delete cleanedDispatch['newTransportationToAdd'];
	delete cleanedDispatch['_showDetails'];

	return cleanedDispatch;
}

export const DispatchUtil = {

	getCompanyIdForUserOptions(source, destination) {
		if (source.isActive === 'true') {
			return source.id;
		} else if (source.isActive === 'false' && destination.isActive === 'true') {
			return destination.id;
		}
		return null;
	},



	validate(dispatch, param) {
		let errors = [];

		let companiesObj = param.companiesObj;
		let storageLocationsObj = param.storageLocationsObj;
		let connectionsObj = param.connectionsObj;
		let assetTypesObj = param.assetTypesObj;
		let transportationsObj = param.transportationsObj;
		let assetsObj = param.assetsObj;

		let dateDeployed = dispatch.dateDeployed;
		let dateReceived = dispatch.dateReceived;
		let sourceCompany = dispatch.source.company;
		let sourceLoc = dispatch.source.storageLocation;
		let destinationCompany = dispatch.destination.company;
		let destinationLoc = dispatch.destination.storageLocation;
		let status = dispatch.status;
		let notes = dispatch.notes;
		let transportationCompany = dispatch.transportation.company;
		let driver = dispatch.driver.name;
		let assistants = dispatch.driver.assistants;
		let plateNo = dispatch.transportation.plateNo;
		let assets = dispatch.assets;

		let currTimeStamp = DateUtil.getCurrentTimestamp();

		// Dispatch Date
		if (!dateDeployed && dateDeployed.length === 0) {
			errors.push('Date Deployed is required.');
		} else if (dateDeployed && dateDeployed.length > 0) {
			if (!ImportUtil.isValidDate(dateDeployed)) {
				errors.push('Invalid date deployed, "' + dateDeployed + '". Valid format: MM-DD-YYYY or MM/DD/YYYY');
			} else {
				let dateDeployedTimeStamp = DateUtil.getTimestamp(dateDeployed);
				if (dateDeployedTimeStamp > currTimeStamp) {
					errors.push('Invalid date deployed, "' + dateDeployed + '". Future date is not allowed.');
				}
			}
		}

		if (status === 'Received' && !dateReceived && dateReceived.length === 0) {
			errors.push('Date Received is required for dispatch with "Received" status.');
		} else if (status === 'Received' && dateReceived && dateReceived.length > 0) {
			if (!ImportUtil.isValidDate(dateReceived)) {
				errors.push('Invalid date received,"' + dateReceived + '". Valid format: MM-DD-YYYY or MM/DD/YYYY');
			} else {
				let dateReceivedTimeStamp = DateUtil.getTimestamp(dateReceived);
				if (dateReceivedTimeStamp > currTimeStamp) {
					errors.push('Invalid date received, "' + dateReceived + '". Future date is not allowed.');
				} else if (new Date(dateReceived) < new Date(dateDeployed)) {
					errors.push('Invalid date received, "' + dateReceived + '". Date received should not be before date deployed.');
				}
			}
		}

		// Status
		else if (!status && status.length === 0) {
			errors.push('Status is required.');
		} else if (status !== 'Received') {
			errors.push('Invalid dispatch status. Received is only the valid dispatch status for import.');
		}


		// Source and Destination
		if (!sourceCompany || sourceCompany.length === 0) {
			errors.push('Source Company is required.');
		}
		if (!sourceLoc || sourceLoc.length === 0) {
			errors.push('Source Storage Location is required.');
		}
		if (!destinationCompany || destinationCompany.length === 0) {
			errors.push('Destination Company is required.');
		}
		if (!destinationLoc || destinationLoc.length === 0) {
			errors.push('Destination Storage Location is required.');
		}
		if (sourceCompany && !companiesObj[sourceCompany]) {
			errors.push('Source Company ' + sourceCompany + ' does not exists.');
		}
		if (destinationCompany && !companiesObj[destinationCompany]) {
			errors.push('Destination Company ' + destinationCompany + ' does not exists.');
		}
		if (sourceLoc && !storageLocationsObj[sourceLoc]) {
			errors.push('Source Storage Location ' + sourceLoc + ' does not exists.');
		}
		if (destinationLoc && !storageLocationsObj[destinationLoc]) {
			errors.push('Destination Storage Location ' + destinationLoc + ' does not exists.');
		}
		if (sourceCompany === destinationCompany && sourceLoc === destinationLoc) {
			errors.push('Source and Destination should not be the same.');
		}
		if (sourceCompany !== destinationCompany && (!connectionsObj[sourceCompany] || !connectionsObj[sourceCompany].includes(destinationCompany))) {
			errors.push(`There is no existing connection(s) between ${sourceCompany} and ${destinationCompany}.`);
		}

		// Notes
		if (!notes && notes.length === 0) {
			errors.push('Notes/Remarks is required.');
		} else if (!config.remarksRegex.test(notes)) {
			errors.push('Notes/Remarks should only contain letters, numbers and these special characters: &\'"-,/():;!*[]');
		}

		// Driver
		if (!driver && driver.length === 0) {
			errors.push('Driver is required.');
		}

		// Transportation
		if (!transportationCompany && transportationCompany.length === 0) {
			errors.push('Transportation is required.');
		} else if (!companiesObj[transportationCompany]) {
			errors.push(`Transportation Company${transportationCompany} does not exists.`);
		}

		if (!plateNo && plateNo.length === 0) {
			errors.push('Plate no is required.');
		} else if (!ValidationUtil.isValidPlateNo(plateNo)) {
			errors.push('Invalid plate no, "' + plateNo + '".');
		} else if (!transportationsObj[plateNo]) {
			errors.push('Vehicle plate no ' + plateNo + ', is non-existent. Add this vehicle first in the Account Setup -> Transportation.');
		}

		if (!driver || driver.length === 0) {
			errors.push('Driver is required.');
		} else if (!ValidationUtil.isAlphaWithWhiteSpace(driver)) {
			errors.push('Invalid driver name, "' + driver + '"');
		}

		if (assistants.length > 0 && !ValidationUtil.isAlphaWithWhiteSpaceAndComma(assistants)) {
			errors.push('Invalid assistant(s) name,"' + assistants + '".');
		}

		// Assets
		if (_.isEmpty(assets)) {
			errors.push('Dispatch should have at least 1 asset to proceed this import.');
		} else if (ValidationUtil.hasInvalidAsset(assetTypesObj, assets)) {
			let errMsg = 'At least 1 invalid asset code is not allowed. The following are invalid asset(s): ';
			errMsg += ValidationUtil.getInvalidAssets(assetTypesObj, assets).join();
			errors.push(errMsg);
		} else if (_.size(assetsObj) < _.size(assets)) {
			errors.push('At least 1 non-existent asset is not allowed to proceed this import.');
		} else if (ValidationUtil.hasAssetWithInTransitStatus(assets, assetsObj)) {
			let assetsInTransit = ValidationUtil.getAssetWithInTransitStatus(assets, assetsObj);
			errors.push('Importing assets with in-transit status is not allowed. Please check the following asset(s): ' + assetsInTransit);
		}

		// Check if all assets has corresponding Asset Type Code
		// For now, only allow the asset codes with corresponding asset type code 

		return errors;
	},

	isInactiveCompany(company) {
		return company.isActive === 'false' ? 'true' : 'false';
	},

	isValidTransportation(transportation) {
		let { company, plateNo } = transportation;

		if (!_.isEmpty(transportation)) {
			return !(!company || !plateNo);
		}

		return true;
	},

	getCompanyLocationDisplay(item) {
		return item.company + ' (' + item.storageLocation + ')';
	},

	getDriverLicenseObj(driver) {
		const driversLicense = !_.isEmpty(driver.licenseUrl)
			? {
				url: driver.licenseUrl,
				_isNew: false,
			}
			: {};
		return JSON.parse(JSON.stringify(driversLicense));
	},

	getDispatchedAssets(dispatch) {
		let dispatchedAssets = [];

		let assets = dispatch.assets ? dispatch.assets : [];
		let addedExpectedAssets = dispatch.addedExpectedAssets ? dispatch.addedExpectedAssets : [];

		if (dispatch.creationSource !== config.dispatchCreationSource.DISPATCH) {
			dispatchedAssets = assets;
		} else {
			dispatchedAssets = _.difference(assets, addedExpectedAssets);
		}

		return dispatchedAssets;
	},

	getReceivedAssets(dispatch) {
		return dispatch.actualAssets ? dispatch.actualAssets : [];
	},

	getLostAssets(dispatch) {
		let assets = dispatch.assets ? dispatch.assets : [];
		let addedExpectedAssets = dispatch.addedExpectedAssets ? dispatch.addedExpectedAssets : [];

		let dispatchedAssets = dispatch.creationSource !== config.dispatchCreationSource.DISPATCH ? assets : _.difference(assets, addedExpectedAssets);
		let receivedAssets = dispatch.actualAssets ? dispatch.actualAssets : [];

		return _.difference(dispatchedAssets, receivedAssets);
	},

	getAddedExpectedAssets(dispatch) {
		return dispatch.addedExpectedAssets ? dispatch.addedExpectedAssets : [];
	},

	exceedMaximumAssetTypes,
	getDefaultDispatchObj,
	cleanupFields,
}
